@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.profile_edit {
	position: relative;
	z-index: 100;
	margin-top: 2rem;
	margin-bottom: 6rem;
	background: rgba(0, 0, 0, 0.75);
	border-radius: 20px;
	padding: 30px;
	max-width: 1140px !important;

	@include media($phone_all...) {
		padding: 0 1rem;
	}

	.welcome {
		position: relative;

		h1 {
			font-size: 1.75rem;
			font-weight: 300;
		}

		p {
			font-size: 0.8rem;
			line-height: 1.1rem;
		}
	}

	.email_box {
		display: flex;

		.edit_email {
			height: 50px;
			width: 50px;
			border-radius: 8px;
			background: linear-gradient(#000000, #000000) padding-box,
				linear-gradient(to bottom, #016eda, #d900c0) border-box;
			border: 2px solid transparent;
			margin-top: 34px;
			margin-left: 10px;

			svg {
				font-size: 18px;
			}
		}
	}

	.selectbox {
		div:first-child {
			width: 100% !important;
		}
	}

	.form_label {
		h3 {
			margin-top: 0;
			margin-bottom: 10px;
			font-weight: 400;
			font-size: 1rem;
			line-height: 1.5;
		}
	}

	.edit_controls {
		display: inline-block;
		text-align: right;
		margin-top: 0.7rem;

		@include media($phone_all...) {
			text-align: center;
		}

		button {
			margin-right: 12px;
		}

		.cancel_btn {
			height: initial;
			padding: 0.5rem 0.5rem;
			color: #5dccf2;
			border: none;
			font-size: 0.9;
			text-transform: none;
		}
	}

	.edit_controls_bottom {
		@extend .edit_controls;
		margin-top: 0;
		margin-bottom: 3rem;
	}

	.edit_form {
		margin-top: 1rem;

		@include media($phone_all...) {
			padding-top: 0 !important;
		}

		> div {
			padding-top: 16px;
			align-content: flex-start;
		}

		.icon_container {
			position: relative;

			h3 {
				@include media($phone_all...) {
					display: none;
				}
			}

			button {
				position: absolute;
				top: 55px;

				@include media($phone_all...) {
					top: 23px;
				}

				svg {
					color: #7c7c7c;
				}
			}
		}

		.social_item {
			display: flex;
			align-items: center;

			span {
				margin-left: 10px;
				margin-right: auto;
			}

			svg {
				font-size: 1rem;
			}
		}
	}
}
