@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

@keyframes Gradient {
	0% {
		background-position: 100% 50%;
	}

	100% {
		background-position: -33% 50%;
	}
}

.overview {
	width: 100%;
	display: flex;
	justify-content: space-around;
	position: relative;
	transition: all 0.3s ease-in-out;
	height: 100%;
	gap: 10px;

	@include media($phone_all...) {
		display: flex;
		flex-direction: column;
		gap: 16px;
		max-width: 100%;
	}

	&>div {
		width: 100%;
		height: auto;
		border-radius: 16px;
		position: relative;
		display: flex;
		flex-direction: column;
		max-width: 257px;

		&:last-child {
			margin-right: 0 !important;
		}

		@include media($phone_all...) {
			max-width: 100% !important;
		}

		&.both_section {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 16px;

			.hodl_period,
			.balance_breakdown {
				min-height: 85px;
				display: flex;
				align-items: center;
				justify-content: center;

				h2 {
					line-height: 20px !important;

					@include media($phone_all...) {
						line-height: 20px;
					}
				}
			}
		}

		.top {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;

			h2 {
				font-family: var(--font-manrope);
				font-size: 16px;
				font-weight: 700;
				line-height: 24px;
				letter-spacing: -0.018em;
				display: flex;
				flex-wrap: nowrap;
				justify-content: center !important;
				gap: 8px;

				span {
					font-size: medium;
					font-weight: 300;

					img {
						width: 17px;
						height: 17px;
					}

					display: flex;
					align-items: center;
					justify-content: center;
					top: 0px;

					&::before {
						display: none !important;
					}
				}

				.inside_header {
					font-family: var(--font-manrope);
					font-size: 16px;
					font-weight: 700;
					line-height: 17px;
					letter-spacing: -0.018em;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}

			h2.header_token_hodl {
				font-family: var(--font-manrope);
				font-size: 16px;
				font-weight: 700;
				line-height: 32px;
				letter-spacing: -0.018em;
				display: flex;
				flex-wrap: nowrap;
				justify-content: center !important;
				margin: 0px;
				gap: 8px;

				span {
					font-size: medium;

					img {
						width: 17px;
						height: 17px;
					}

					display: flex;
					align-items: center;
					justify-content: center;
					top: 0px;

					&::before {
						display: none !important;
					}
				}
			}

			.flex_center {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
			}

			.center {
				text-align: center;

				img {
					position: relative;
					top: 2px;
					display: inline-block;
					width: 32px;
					height: auto;
					margin-right: 10px;
				}

				.countdown {
					font-size: 12px;
					font-weight: 600;
					margin-bottom: 4px;
					min-height: 25.59px;
				}

				.progress_container {
					position: relative;
					margin: 0;
					max-width: 13.5rem;
					width: 100%;
				}

				.flex_Gems {
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 8px auto;

					&.gem_text {
						font-family: var(--font-manrope);
						font-weight: 600 !important;
						line-height: 24px !important;
						text-align: center;
					}

					& h3 {
						flex: 1 1 20%;
						max-width: 20%;
						text-wrap: nowrap;
						font-size: 1rem !important;
						font-family: var(--font-manrope);

						&.flex_width {
							flex: 1 1 40%;
							font-size: 0.75rem !important;
							font-weight: 600;
							max-width: fit-content;
							margin-right: 8px;
						}
					}

					& img {
						flex: 1 1 17px;
						max-width: 17px;
						margin: 0px;
						margin-bottom: 4px;
						margin-right: 5px;
					}
				}

				h1 {
					display: inline-block;
				}

				.speedUpBtn {
					display: block;
					// margin-top: 12px;
					font-size: 16px;
					font-weight: 600;
					width: 100%;
					border: none;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					padding: 8px;
					max-height: 40px;

					border-radius: 12px;

					img {
						width: 16px;
						margin-right: 4px;
					}

					&:disabled {
						opacity: 0.6;
						color: #fff;
					}

					background-image: linear-gradient(270deg, #4e4bde, #bd02b2, #5348dc);
					background-size: 400% 100%;
					transition: all 1s ease-in-out;
					animation: Gradient 11s linear 1s infinite both;
					animation-timing-function: ease-in-out;
				}
			}
		}

		.bottom {
			text-align: center;

			.back_to_main {
				padding: 4px 16px;
				color: rgba(255, 255, 255, 1);
				border-radius: 8px;
				background: #4e4bde;
				font-family: Titillium Web;
				font-size: 16px;
				font-weight: 600;
				line-height: 24px;
				text-align: center;
				animation-timing-function: ease-out;
				animation-duration: 300ms;

				svg {
					font-size: 16px;
					color: rgba(255, 255, 255, 0.5);
				}
			}

			.btn_bonus {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 8px;
			}

			.bonus {
				min-width: 108px;
				min-height: 40px;
				padding: 8px 12px 8px 12px;
				border-radius: 12px;
				background: #363636;
				font-family: Titillium Web;
				font-size: 16px;
				font-weight: 600;
				line-height: 24px;
				text-align: center;
				text-wrap: nowrap;
				color: #ffffff;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			h2 {
				font-family: var(--font-manrope);
				font-size: 16px;
				font-weight: 400;
				line-height: 17px;
				letter-spacing: -0.018em;
				color: #ffffff;
			}
		}

		.center {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			img {
				width: 32px;
				height: 32x;
			}

			h1 {
				font-family: var(--font-manrope);
				font-size: 40px;
				font-weight: 700;
				line-height: 32px;
				letter-spacing: -0.018em;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 12px;

				img {
					width: 32px;
					height: 32x;
				}
			}

			button {
				padding: 4px 16px;
				color: #fff;
				border-radius: 8px;
				background: #2e2bb7cc;
				font-size: 12px;

				svg {
					font-size: 12px;
				}
			}

			.speedUpBtn {
				display: block;
				// margin-top: 12px;
				font-size: 16px;
				font-weight: 600;
				width: 100%;
				border: none;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				padding: 8px;
				max-height: 40px;

				border-radius: 12px;

				img {
					width: 16px;
					margin-right: 4px;
				}

				&:disabled {
					opacity: 0.6;
					color: #fff;
				}

				background-image: linear-gradient(270deg, #4e4bde, #bd02b2, #5348dc);
				background-size: 400% 100%;
				transition: all 1s ease-in-out;
				animation: Gradient 11s linear 1s infinite both;
				animation-timing-function: ease-in-out;
			}
		}

		.flex_center {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
		}
	}

	.number_copy {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;

		h2 {
			font-family: var(--font-manrope);
			font-size: 16px;
			font-weight: 400;
			line-height: 20px;
			letter-spacing: -0.018em;
			display: flex;
			align-items: center;
			justify-content: center;

			&.hodl_completed {
				text-align: center;
				font-size: 15px;
				margin-top: 4px;
			}
		}
	}

	.text {
		font-family: 'var(--font-manrope)';
		font-style: normal;
		line-height: 32px;
		letter-spacing: -0.018em;
		color: #ffffff;

		span {
			cursor: pointer;

			svg {
				font-size: 10px;
			}
		}
	}

	.lineHeight_17 {
		font-weight: 400;
		line-height: 17px !important;
	}

	.strong {
		font-weight: 700;
		line-height: 17px !important;
	}

	.hodl_period,
	.diamond_circle_HODL,
	.balance_breakdown,
	.bones_earings,
	.whitelisted_wallet {
		background: linear-gradient(249.35deg,
				rgba(206, 206, 206, 0.15) 5.78%,
				rgba(156, 148, 255, 0.15) 43.38%,
				rgba(186, 183, 225, 0.15) 84.93%) !important;
		border: 1px solid #ffffff80 !important;
		border-radius: 16px;

		.body {
			@include media($phone_all...) {
				display: flex;
				gap: 8px;
				flex-direction: column;
			}

			padding: 15px;
		}
	}

	.diamond_HODL .body {
		padding: 10px;
	}

	.bones_earings,
	.diamond_circle_HODL {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		// height: 100%;
		align-items: center;

		@include media($phone_all...) {
			max-height: 175px;
			min-height: 175px;

			height: 100%;
		}

		.body {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
		}
	}

	.diamond_circle_HODL {
		position: relative;

		&:after {
			content: '';
			position: absolute;
			width: 60px;
			height: 60px;
			background: url('../../../../assets/images/card_diamond.png') no-repeat;
			background-size: contain;
			top: -35px;
			right: -2px;
		}
	}

	.speedUp {
		border: 1px solid #4e4bde;
		background: linear-gradient(207.51deg,
				rgba(78, 75, 222, 0.25) -8.93%,
				rgba(26, 23, 62, 0.25) 89.5%);
	}

	.speedUp {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: 16px;
		border: 1px solid #4e4bde;
		background: linear-gradient(207.51deg,
				rgba(78, 75, 222, 0.25) -8.93%,
				rgba(26, 23, 62, 0.25) 89.5%);
		padding: 16px;

		@include media($phone_all...) {
			margin-bottom: 15px;
			margin-right: 0px !important;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}

		.body {
			height: 100%;

			@include media($phone_all...) {
				flex: 1 1 75%;
				max-width: 75%;
			}

			.top {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
		}

		h2 {
			font-family: var(--font-manrope);
			font-weight: 700;
			line-height: 24px;
			letter-spacing: -0.018em;
			text-align: center;
			text-wrap: nowrap;
			display: flex;
			flex-wrap: nowrap;

			span {
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				top: 0px;
				margin-left: 8px;

				svg {
					position: relative;
					font-size: 9px;
				}

				img {
					width: 17px;
					height: 17px;
				}
			}
		}

		.flex_column {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			justify-content: center;
			gap: 0px;
		}
	}

	.whitelisted_wallet {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 16px;
		border: 1px solid #4e4bde;
		background: linear-gradient(207.51deg,
				rgba(78, 75, 222, 0.25) -8.93%,
				rgba(26, 23, 62, 0.25) 89.5%);
		padding: 16px;
		margin-right: 0;

		.top {
			flex-direction: column;
		}

		@include media($phone_all...) {
			padding: 26px 25px;
			margin-top: 0px !important;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.wallet_info {
			.help_icon {
				right: 15px !important;
				top: 15px !important;
				max-width: 120px;
				text-align: center;
				cursor: pointer;
				opacity: 0.5;
				height: 20px;
				margin-bottom: 10px;

				svg {
					font-size: 15px;
					margin: 0;

					@include media($phone_all...) {
						font-size: 20px;
					}
				}

				&:hover {
					opacity: 1;
				}
			}
		}

		.text {
			margin-bottom: 18px;
			gap: 8px;
		}

		button {
			font-size: 16px !important;
			font-weight: 600;
			background-image: none !important;
			padding: 0.25rem !important;

			&.remove {
				padding: 0 !important;
				min-width: initial;
				background: rgba(46, 44, 106, 0.5) !important;
				border-radius: 6px;
				color: rgba(255, 255, 255, 0.5) !important;
				flex: 1 1 10%;
				max-width: 10%;
				padding: 9px 18px !important;

				svg {
					font-size: 14px;
					color: rgba(255, 255, 255, 0.5) !important;
				}

				&::before {
					background: none;
				}
			}
		}

		button::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 5px;
			border: 0.15rem solid transparent;
			background: linear-gradient(45deg, #016eda, #d900c0) border-box;
			-webkit-mask: linear-gradient(#fff 0 0) padding-box,
				linear-gradient(#fff 0 0);
			-webkit-mask-composite: destination-out;
			mask-composite: exclude;
		}

		@include media($phone_all...) {
			margin-top: 10px;
		}

		.content {
			display: flex;

			@include media($phone_all...) {
				flex: 1 1 75%;
				max-width: 75%;
			}

			&>div {
				flex: 1;
			}

			.info {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: left;

				p {
					color: #fff;
					font-family: var(--font-manrope);
					font-size: 14px;
					font-style: normal;
					font-weight: 700;
					line-height: 22px;
					letter-spacing: -0.252px;
					margin-bottom: 8px;
					text-align: center;
					max-width: 120px;

					&.address {
						opacity: 0.5;
					}

					svg {
						margin-right: 8px;
						font-size: 18px;
					}
				}

				.address {
					font-size: 14px;
					font-weight: 600;
					background: rgba(38, 34, 98, 0.5);
					color: rgba(255, 255, 255, 0.5) !important;
					align-content: center;
					text-align: center;
					padding: 8px;
					border-radius: 25px;
					max-width: 120px;
				}

				button {
					font-family: 'Titillium Web', sans-serif;
					width: 100%;
					border-radius: 25px;
					background: rgba(38, 34, 98, 1);
					margin-top: 0;
					max-width: 120px;
					font-size: 16px;
					padding: 8px !important;
					font-weight: 600;

					@include media($phone_all...) {
						max-width: 140px;
						padding: 8px 14px !important;
					}

					&.remove {
						background: rgba(38, 34, 98, 0.5);
						color: rgba(255, 255, 255, 0.5) !important;
						align-content: center;

						svg {
							font-size: 20px;
							opacity: 0.5;
							margin-left: 5px;
						}
					}

					&.remove::before {
						border: none !important;
					}
				}
			}

			.whiteAddress {
				font-size: 16px;
				font-weight: 300;
				margin-right: 10px;
				padding: 4px 18px;
				background: rgba(38, 34, 98, 0.5);
				border-radius: 6px;
				color: rgba(255, 255, 255, 0.5);
				flex: 1 1 78%;
				max-width: 78%;

				@include media($phone_all...) {
					flex: 1 1 75%;
					max-width: 75%;
				}

				svg {
					color: rgba(255, 255, 255, 0.5);
					font-size: 14px;
					margin-right: 5px;
				}
			}

			.buttons {
				display: flex;
				justify-content: space-between;
				margin-top: 18px;

				p {
					justify-content: center;
					align-content: center;
					font-weight: 600;
					font-size: 16px;
					margin-top: 8px;
					margin-right: 10px;
				}

				button {
					border-radius: 12px;
					background: #4e4bde;
					margin: 8px 4px 0;
					padding: 8px 17px !important;
					font-weight: 600;
					font-size: 16px;

					@include media($phone_all...) {
						max-width: 140px;
						padding: 8px 14px !important;
					}

					&.remove {
						background: #262262;
					}
				}

				.transferIn {
					min-width: 69.25px;
					height: 40px;
				}

				.transferOut {
					min-width: 69.25px;
					height: 40px;

					background: #262262;
				}

				.transferIn::before,
				.transferOut::before {
					border: none;
					border-radius: initial;
				}
			}
		}
	}
}

.speedUp h2.text,
h2.text {
	display: flex;
	flex-wrap: nowrap;
	justify-content: center !important;

	span {
		font-size: medium;

		img {
			width: 17px;
			height: 17px;
		}

		display: flex;
		align-items: center;
		justify-content: center;
		top: 0px;

		&::before {
			display: none !important;
		}
	}
}