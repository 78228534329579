@import 'ThemeScss';
.transinfo-section {
	margin-top: 2rem;
	max-width: 720px;
	margin: 6rem auto 0;
	h1 {
		font-size: 28px;
	}
	.wallet-table {
		background-color: #121212;
		table {
			border-collapse: separate;
			border-spacing: 0px 10px;
		}
		thead {
			tr {
				background-color: #121212;
				color: #ffffff;
				font-size: 0.7rem;
				margin-bottom: 10px;
			}
		}
		tbody tr {
			background-color: #222222;
			margin-bottom: 0.7rem;
			td {
				color: #ffffff;
				font-size: 0.9rem;
				border: none;
				&:first-child {
					text-align: left;
					font-weight: bold;
					padding-left: 0.75rem;
				}
			}
		}
	}
}
