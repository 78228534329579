@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.header {
	color: #fff;
	padding: 0 0 16px;
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	h3 {
		font-size: 20px;
	}

	p {
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		margin-top: 0.75rem;
		margin-bottom: 0;
		line-height: 1.3rem;
		display: block;

		a {
			text-decoration: underline;
		}
	}
}

.content {
	padding: 16px 0;
	display: flex;
	flex-direction: row;
	justify-content: center;

	@include media($phone_all...) {
		flex-direction: column;
		align-items: center;
	}

	button {
		min-width: 106px;
		height: 35px;
		border-radius: 25px;
		background: #4e4bde;
		margin-right: 12px;

		@include media($phone_all...) {
			margin-bottom: 10px;
			margin-right: 0;
			min-width: 80%;
			width: auto;
		}

		&:hover {
			background: #4e4bde;
		}
	}
}
