@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.profile_tab {
	max-width: 100%;

	@include media($phone_all...) {
		max-width: calc(100vw - 16px);
	}

	>div {
		border: none;
	}

	[role='tabpanel'] {
		display: inline;
		margin-top: 0;
		width: 100%;
	}

	.tabs_list {
		border-top: none;
		padding: 1rem 0 0;

		>div>div {
			justify-content: flex-start;
			align-items: baseline;
		}

		button {
			margin-right: 50px;
			color: #bcb2aa;
			font-size: 1rem;
			font-weight: 600;
			text-transform: capitalize;
			max-width: fit-content;
			align-items: flex-start;
			min-width: 20px;
			padding: 0.25rem 0.75rem;
			border-bottom: 0.2rem solid transparent;

			@include media($phone_all...) {
				margin-right: 0;
				font-size: 14px;
				padding: 6px;
			}

			&:hover {
				color: $whiteColor;
				border-color: #fff;
			}
		}

		.tab_custom_indicator {
			background-color: #fff;
			height: 0.2rem;
		}
	}
}