@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.fraud_container {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 30px;
	border-radius: 10px;
	background: #4545c8;
	cursor: pointer;

	@include media($phone_all...) {
		margin-top: 0;
	}

	.fraud_message {
		color: #fff;
		font-size: 15px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		letter-spacing: normal;
		cursor: pointer;
		width: 100%;
		text-align: center;
	}
}
