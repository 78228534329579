@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.settings_section {
	margin-top: 2rem;
	margin-bottom: 6rem;
	position: relative;
	z-index: 2;
	background: rgba(0, 0, 0, 0.75);
	border-radius: 20px;
	padding: 30px 90px;

	@include media($phone_all...) {
		padding: 30px 20px;
	}

	.controls {
		display: inline-block;
		text-align: right;
		margin-top: 2rem;
	}

	h1 {
		font-size: 1.25rem;
		margin-top: 2.5rem;
	}

	h4 {
		font-weight: 300;
		margin-bottom: 0.5rem;
	}

	p {
		font-size: 0.9rem;
		line-height: 1.1rem;
		max-width: 90%;
	}

	.filter {
		margin-bottom: 0.75rem;
	}

	.triggeredOpts {
		margin-top: 1rem;
		margin-left: 1.25rem;

		label {
			margin-bottom: -0.5rem;
		}
	}
}