@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.product-container {
	div {
		width: 255px !important;
	}
}

.profile_section {
	margin-top: 2rem;
	margin-bottom: 4rem;
	position: relative;
	z-index: 2;

	@include media($phone_all...) {
		margin-top: 5rem;
		padding: 0 10px;
	}

	.verify_email,
	.change_email {
		border-radius: 10px;
		background: #4e4bde;
		margin-bottom: 35px;
		font-size: 15px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		text-align: center;
		padding: 12px;
		display: flex;
		justify-content: center;
		cursor: pointer;

		@include media($phone_all...) {
			line-height: 16px;
			text-align: left;
			margin-bottom: 20px;
		}

		svg {
			margin-right: 6px;
			font-size: 15px;

			@include media($phone_all...) {
				margin-right: 10px;
			}
		}

		a {
			color: #fff;
		}
	}

	.change_email {
		background: #01acbd;
	}

	.profile_header {
		position: relative;
		overflow: hidden;
		border-radius: 15px;
		display: flex;
		background: #000;

		&.no_dashboard {
			max-height: 255px;
			height: 100%;
		}

		img {
			display: block;
			width: 100%;
			height: 100%;

			@include media($phone_all...) {
				height: 100%;
			}
		}
	}

	.profile_wrapper {
		display: flex;
		position: relative;
	}

	.profile_info {
		display: flex;
		position: relative;
		margin-bottom: 20px;

		.avatar_badge {
			height: 32px;
			width: 32px;
		}

		.avatar_ring {
			position: absolute;
			height: 70px;
			width: 70px;
		}

		.info_container {
			margin-left: 10px;
			display: flex;
			flex-direction: column;
			line-height: 1rem;
			justify-content: center;
			color: #fff;

			.display_name {
				position: relative;
				display: flex;
				font-family: 'Titillium Web';
				font-style: normal;
				font-weight: 700;
				font-size: 20px;
				line-height: 24px;

				@include media($phone_all...) {
					max-width: 120px;
				}

				svg {
					position: absolute;
					cursor: pointer;
					color: #1aeff3;
					font-size: 16px;
					margin-left: 4px;
					margin-top: 0;
				}
			}

			.profession {
				font-style: normal;
				font-weight: 400;
				font-size: 12px;
			}

			.membership {
				font-weight: 600;
				font-size: 12px;
				line-height: 24px;
			}
		}

		button {
			float: right;
			margin-left: 25px;
		}
	}

	.btns_container {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 12px;

		@include media($phone_all...) {
			flex-direction: column;
			justify-content: flex-start;
			gap: 6px;
		}

		a,
		button {
			border-radius: 25px;
			color: #000;
			height: 36px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 130px;
			font-family: Titillium Web;
		}

		.edit_profile_btn {
			background: #152d53;
			color: #fff;
			font-size: 13px;
		}

		.follow_btn {
			font-size: 15px;
			background: #ffffff;
			font-weight: 700;
		}
	}
}