.content {
	padding: 0;

	.social_login {
		display: flex;
		justify-content: space-between;
		margin-right: 64px;
	}

	.fields {
		input {
			width: 82%;
		}

		.row {
			display: flex;
			width: 90%;

			.last_name,
			.first_name {
				width: 100%;
				margin-bottom: 12px;
			}
		}

		.email_input {
			margin-bottom: 12px;
		}

		.password_container {
			display: flex;
			margin-bottom: 12px;

			button {
				height: 45px;
			}

			input {
				width: 100%;
			}

			.form_helper {
				width: 100%;
			}
		}
	}
}

.title {
	color: #fff;
	font-size: 2rem;
	line-height: 2.3rem;
	padding: 0 0 16px;
	font-weight: 600;

	span {
		font-weight: 400;
		font-size: 1rem;
		margin-top: 0.75rem;
		margin-bottom: 0;
		line-height: 1.3rem;
		display: block;
		letter-spacing: 0.1rem;

		span {
			color: #4cb6eb;
			display: inline;
		}
	}
}

.actions {
	justify-content: flex-start;
	margin-top: 2rem;

	.agree {
		letter-spacing: 1px;
		font-size: 0.7rem;
		font-weight: 300;
		margin: 0;

		a {
			color: #fff;
			cursor: pointer;
			text-decoration: underline;
		}
	}

	.newsletters {
		span {
			letter-spacing: 1px;
			font-size: 0.7rem;
			font-weight: 300;

			svg {
				color: #fff;
				font-size: 14px;
			}
		}
	}
}
