.dialog {
	z-index: 1350 !important;

	.dialog_paper {
		width: 348px;
		border-radius: 30px;
		border: 1px solid #2e2bba;
		background: linear-gradient(180deg, #2e2bba 0%, #1e1d47 100%);

		.dialog_content {
			padding: 24px;

			h2 {
				width: 100%;
				color: #fff;
				text-align: center;
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: 30px;
				letter-spacing: normal;
				margin-bottom: 10px;
			}

			h3 {
				width: 100%;
				color: #fff;
				text-align: center;
				font-size: 1rem;
				font-style: normal;
				font-weight: 600;
				line-height: 24.34px;
				letter-spacing: normal;
				margin-bottom: 12px;
			}

			p {
				letter-spacing: normal;
				color: #fff;
				opacity: 0.5;
				text-align: center;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px;
				margin-bottom: 15px;
			}

			.actions {
				display: flex;
				justify-content: center;

				button {
					border-radius: 25px;
					background: #4e4bde;
					color: #fff;
					height: 40px;
					min-width: 142px;
					padding: 0 35px;
					font-weight: 700;
					line-height: 24px;

					&:hover {
						opacity: 0.7;
					}
				}

				.close_btn {
					background: #2e2c6a;
					color: #fff;
					height: 40px;
					min-width: 142px;
					padding: 0 35px;
					font-weight: 700;
					line-height: 24px;

					&:hover {
						opacity: 0.7;
					}
				}
			}

			.icon_btns {
				display: flex;
				justify-content: space-between;
				margin-bottom: 12px;
				width: 100%;

				.left_dir {
					width: 50%;
					display: flex;
					justify-content: flex-start;
				}

				.right_dir {
					width: 50%;
					display: flex;
					justify-content: flex-end;
				}
			}

			.schedules_data_content {
				margin: 24px 0px;

				.grid_data {
					display: flex;
					justify-content: space-between;
					height: 32px;
					align-items: center;
					padding: 4px 16px 4px 16px;

					&:hover {
						background: #2e2c6a;
						border-radius: 8px;
						cursor: pointer;
					}

					span {
						font-size: 1rem;
						color: #fff;
						font-weight: 400;
						line-height: 24.34px;
					}
				}
			}
		}
	}

	&.large {
		.dialog_paper {
			width: auto;
		}

		.dialog_content {
			.actions {
				button {
					padding: 26px 32px;
					font-weight: 700;
					line-height: 24px;
					height: 40px;
					min-width: 142px;
					padding: 0 35px;
				}

				.close_btn {
					background: #2e2c6a;
					color: #fff;
					font-weight: 700;
					line-height: 24px;
					height: 40px;
					min-width: 142px;
					padding: 0 35px;
				}
			}
		}
	}

	&.warning {
		.dialog_paper {
			width: auto;
			overflow: visible;
		}

		img {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: -90px;
			z-index: 10;
			width: 340px;
			height: 155px;
		}

		.dialog_content {
			overflow: auto;

			h2 {
				margin-top: 60px;
			}

			p {
				text-align: left;
				font-size: 18px;
				line-height: 22px;
				margin-bottom: 30px;
			}

			.actions {
				.approve_btn {
					background: #11113a;
					font-weight: 700;
					line-height: 24px;
					height: 40px;
					min-width: 142px;
					padding: 0 35px;
				}

				.close_btn {
					background: #2e2c6a;
					color: #fff;
					font-weight: 700;
					line-height: 24px;
					height: 40px;
					min-width: 142px;
					padding: 0 35px;
				}
			}
		}
	}
}
