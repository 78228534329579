@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.hero {
	background: transparent url('~assets/images/homepage/hero.webp') no-repeat top;
	width: 1480px;
	max-width: 100%;
	height: 100vh;
	max-height: 562px;
	display: inline-grid !important;
	grid-template-rows: [row1-start] 50% [row1-end] 175px [third-line] auto [last-line];
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 3;

	@include media($phone_all...) {
		max-height: 450px;
	}

	h2 {
		font-style: normal;
		font-weight: 400;
		font-size: 100px;
		line-height: 101px;
		text-align: center;
		color: #ffffff;
		margin-top: 200px;

		span {
			font-weight: 600;
		}

		@include media($phone_all...) {
			font-size: 50px;
			line-height: 51px;
			margin-top: 100px;
		}
	}

	.shine {
		opacity: 0.08;
		width: 100%;
		height: 1245px !important;
		position: absolute;
		z-index: 2;
		bottom: -1100px;
	}

	.explore_btn {
		position: relative;
		z-index: 4;
		margin: 0 auto;
		width: 290px;
		padding: 15px 55px;
		background: linear-gradient(
			180deg,
			rgba(195, 98, 255, 0.1) 0%,
			rgba(93, 69, 246, 0.1) 100%
		);
		border: 3px solid #ffffff;
		backdrop-filter: blur(12.5px);
		border-radius: 5px;
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 32px;
		text-align: center;
		letter-spacing: -0.025em;
		color: #ffffff;
		transition: all 300ms ease-in;

		&:hover {
			background: linear-gradient(
				180deg,
				rgba(195, 98, 255, 0.5) 0%,
				rgba(93, 69, 246, 0.5) 100%
			);
		}
	}
}
