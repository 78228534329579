@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.project_edit {
	margin-top: 2rem;
	margin-bottom: 6rem;
	position: relative;
	z-index: 2;
	background: none;
	background: rgba(0, 0, 0, 0.75);
	border-radius: 20px;
	padding: 30px;
	max-width: 1140px !important;

	@include media($phone_all...) {
		margin-top: 4rem;
	}

	.welcome {
		position: relative;

		h1 {
			font-size: 1.75rem;
			font-weight: 300;

			@include media($phone_all...) {
				font-size: 1.45rem;
				margin: 0.75rem 0;
			}
		}

		p {
			font-size: 0.8rem;
			line-height: 1.1rem;
		}
	}

	.edit_controls {
		display: inline-block;
		text-align: right;
		margin-top: 0.7rem;

		@include media($phone_all...) {
			text-align: center;
		}

		> div {
			scroll-margin-top: 100px;
		}

		button {
			border: none;
			padding: 0.25rem 1rem !important;
			margin-right: 12px;
		}

		.status {
			display: flex;
			align-items: center;
			padding: 0px 12px;
			font-weight: 600;
			background: #a5abe2;
			color: #000;
			border-radius: 2px;
			font-size: 0.7rem;
			height: 30px;
		}

		.preview_btn {
			font-weight: 300;

			@include media($phone_all...) {
				display: none;
			}
		}

		.save_btn {
			font-weight: 700;

			&:disabled {
				opacity: 0.25;
				pointer-events: none;
			}
		}

		.save_btn,
		.cancel_btn {
			height: initial;
			padding: 0.5rem 0.5rem;
			color: #fff;
			border: none;
			font-size: 0.9;
			text-transform: none;
		}
	}

	.edit_controls_bottom {
		@extend .edit_controls;
		margin-top: 3rem;
	}

	.edit_form {
		margin-top: 1rem;

		@include media($phone_all...) {
			margin-top: 0;
		}

		> div {
			scroll-margin-top: 100px;
			align-content: flex-start;
		}

		.video_info {
			display: flex;
			margin-top: 0.5rem;
			border: 0.1rem solid #2f3336;
			border-radius: 8px;

			img {
				width: 9rem;
				border-right: 0.1rem solid #2f3336;
				border-radius: 8px 0 0 8px;
			}

			h3 {
				padding: 6px;
				font-size: 14.4px;
				font-weight: 700;
			}

			h4 {
				padding: 6px;
				font-size: 0.7rem;
			}
		}
	}
}

.active {
	opacity: 1;
	pointer-events: initial;
}
