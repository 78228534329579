@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.activity_table {
	min-height: 590px;
	margin-top: 20px;
	border-radius: 20px;
	background: #1a173e;
	padding: 20px 30px;
	position: relative;

	.table_container {
		min-height: 500px;
		margin-bottom: 12px;
		display: flex;

		th {
			color: #fff;
			text-transform: uppercase;
			font-family: var(--font-manrope);
			font-size: 10px;
			font-style: normal;
			font-weight: 400;
			line-height: 30px;
			opacity: 0.6;
			letter-spacing: -0.18px;
		}

		p {
			align-self: center;
			width: 100%;
			text-align: center;
		}

		.retry {
			background: #e9010130 !important;
			height: 26px;

			&:disabled {
				color: #ffffff6b;
				font-weight: 400;
			}
		}

		.project {
			display: flex;
			align-items: center;

			.poster {
				width: 30px;
				height: 44px;
				border-radius: 5px;
				border: 2px solid #fff;
				margin-right: 8px;

				@include media($phone_all...) {
					display: none;
				}
			}
		}

		.claim_btn {
			border-radius: 50px;
			background-color: #59b7d8 !important;
			min-width: 94px;
			height: 25px;
			font-size: 14px;
			padding: 3px 15px;

			svg {
				border: none;
				font-size: 16px;
				margin-left: -10px;
			}
		}
	}

	.text {
		font-family: var(--font-inter), sans-serif;
		font-style: normal;
	}

	* {
		background: transparent !important;
	}

	.tableheader {
		text-transform: uppercase;
		font-family: 'var(--font-manrope)';
		font-style: normal;
		font-weight: 400;
		font-size: 10px;
		line-height: 17px;
		letter-spacing: -0.018em;
		color: #ffffff !important;
		opacity: 0.6;
	}

	.text_tbody {
		font-family: var(--font-inter), sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		color: #ffffff;
		border: none;
		padding: 14px 0;
		padding-right: 6px;
	}

	table {
		thead {
			th {
				border: none;
				background-color: #1b173e !important;

				span {
					@extend .tableheader;

					&:hover {
						@extend .tableheader;
					}
				}

				svg {
					font-size: 16px;
					color: #ffffff !important;
				}

				@include media($phone_all...) {
					&:nth-child(4) {
						display: none;
					}
				}
			}
		}

		tbody {
			td {
				@extend .text_tbody;

				a {
					@extend .text_tbody;
				}

				@include media($phone_all...) {
					&:nth-child(4) {
						display: none;
					}
				}
			}

			svg {
				border: 1px solid #484d51;
				border-radius: 50%;
				font-size: 12px;
				padding: 4px;
			}

			.green {
				color: #1e8f2a;
			}

			.red {
				color: #f82e2e;
			}
		}
	}

	.pagination {
		* {
			color: #ffffff;
		}

		button {
			&:disabled {
				* {
					color: #ffffff52;
				}
			}
		}
	}

	.header {
		display: flex;
		align-items: flex-end;
		margin-bottom: 10px;

		.title {
			@extend .text;
			font-weight: 600;
			font-size: 14px;
			line-height: 21px;
			color: #ffffff;
			margin-right: auto;
		}

		.view_only {
			@extend .text;
			font-weight: 500;
			font-size: 12px;
			line-height: 18px;
			text-align: right;
			color: #cdff65;
			cursor: pointer;

			&.selected {
				font-weight: 600;
				border-bottom: 1px solid #cdff65;
			}
		}

		.view_only_earnings {
			@extend .view_only;
			margin-left: 16px;
		}
	}
}
