@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.overview {
	width: 100%;
	display: flex;
	justify-content: space-around;
	@include media($phone_all...) {
		display: block;
		max-width: 100%;
	}
	& > div {
		width: 100%;
		height: 140px;
		margin-right: 10px;
		border-radius: 16px;
		padding: 16px;
		position: relative;
		display: flex;
		flex-direction: column;

		&:last-child {
			margin-right: 0;
		}

		h1 {
			font-size: 24px;
			font-weight: 700;
		}

		h2 {
			margin-right: auto;
			font-weight: 700;
			font-size: 14px;
		}

		.h3 {
			font-weight: 400;
			font-size: 14px;
		}

		.top {
			display: flex;
			align-items: center;
		}

		.center {
			display: flex;
			align-items: center;

			img {
				width: 25px;
				height: 25px;
				margin-right: 5px;
			}
		}
	}

	.text {
		font-family: 'var(--font-manrope)';
		font-style: normal;
		line-height: 32px;
		letter-spacing: -0.018em;
		color: #ffffff;
	}

	.claimed {
		flex-direction: row;
		align-items: flex-start;
		background: transparent url(../../../../assets/images/fgr-back.png)
			no-repeat;
		background-size: contain;

		@include media($phone_all...) {
			width: 100%;
			margin-right: 0;
			margin-bottom: 10px;
		}

		.adornment {
			background: #9664ff;
		}

		.token_icon {
			width: 50px;
			margin-right: 10px;
			margin-top: 10px;
		}

		.center {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}
	}

	.earnings {
		background: transparent url(../../../../assets/images/unclaimed-back.png)
			no-repeat;
		background-size: contain;

		@include media($phone_all...) {
			width: 100%;
			margin-right: 0;
		}
		.adornment {
			background: #ffffff;
		}

		.top {
			svg {
				background: #1d17477d;
				color: #52378c;
				border-radius: 50%;
				padding: 4px;
				font-size: 15px;
				margin-right: 8px;
			}
		}
	}

	.claim_all {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 0;

		@include media($phone_all...) {
			display: none;
		}

		button {
			border-radius: 25px;
			font-size: 1.1rem;
			background: #01995f;
			padding: 8px 47px;
			font-weight: 600;
			display: none;

			> span:first-child {
				margin-right: 3px;
				font-weight: 300;
			}

			&:disabled {
				color: #fff;
				opacity: 0.6;
			}

			&:disabled {
				color: #fff;
				opacity: 0.6;
			}
		}
	}
}
