@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.overview {
	position: relative;
	border-radius: 20px;
	padding: 13px 26px;
	margin: 20px 0 0 0;
	display: flex;
	justify-content: space-around;
	background: #0c0b2f url('../../../../assets/images/stars.svg') no-repeat;
	background-size: cover;
	cursor: pointer;

	@include media($phone_all...) {
		display: block;
	}

	&:after {
		border-radius: 20px;
		position: absolute;
		content: ' ';
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: #000;
		opacity: 0.5;
	}

	.title {
		position: relative;
		z-index: +1;
		font-weight: 600;
		font-size: 18px;
		line-height: 21px;
		padding: 5px 10px;
		border-radius: 5px;
		text-align: center;

		@include media($phone_all...) {
			width: 100%;
		}

		.header {
			display: block;
			background: linear-gradient(45deg, #6b3bfa, #f11e76);
			background: -webkit-linear-gradient(45deg, #6b3bfa, #f11e76);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}

		.value {
			font-weight: 600;
			font-size: 17px;
		}
	}
}

.overview_Hodl {
	position: relative;
	border-radius: 20px;
	padding: 13px 26px;
	margin: 20px 0 0 0;
	display: flex;
	justify-content: space-around;
	background: #11113a url('../../../../assets/images/stars.svg') no-repeat;
	background-size: cover;
	cursor: pointer;
	min-height: 73px;
	@include media($phone_all...) {
		display: block;
		background: #11113a url('../../../../assets/images/stars_mob.svg') no-repeat;
		background-size: cover;
	}

	&:after {
		border-radius: 20px;
		position: absolute;
		content: ' ';
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: #000;
		opacity: 0.5;
	}

	.title {
		position: relative;
		z-index: +1;
		line-height: 21px;
		padding: 5px 10px;
		border-radius: 5px;
		text-align: center;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: center;
		gap: 4px;
		@include media($phone_all...) {
			width: 100%;
			justify-content: space-between;
			padding: 2px 10px;
		}

		.header {
			font-family: var(--font-inter), sans-serif;
			font-size: 14px;
			font-weight: 600;
			line-height: 21px;
			text-align: left;
			&:after {
				content: ':';
			}
		}

		p span u,
		p span {
			font-family: var(--font-inter), sans-serif;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
			text-align: left;
		}
	}
}
