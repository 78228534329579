@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.wallet_tab {
	.header {
		display: flex;
		margin: 20px 0 30px;
		align-items: flex-end;
		position: relative;

		&.header_with_btn {
			justify-content: space-between;
			align-items: center;
		}

		@include media($phone_all...) {
			display: initial;
			margin-bottom: 20px;
		}

		h1 {
			font-weight: 400;
			font-size: 30px;
			line-height: 24px;
			font-feature-settings: 'tnum' on, 'lnum' on, 'ss04' on;
			color: #fff;
			margin-right: 45px;
			text-wrap: nowrap;

			@include media($phone_all...) {
				font-size: 26px;
				line-height: 33px;
			}
		}

		span {
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 18px;
			text-align: right;
			color: #cdff65;
			cursor: pointer;
			margin-right: 45px;
			text-wrap: nowrap;

			@include media($phone_all...) {
				font-size: 14px;
				display: inline-block;
				margin-top: 15px;
				margin-right: 20px;
			}

			&.selected {
				font-weight: 600;
				border-bottom: 1px solid #cdff65;
			}
		}

		.btns_container {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.edit_profile_btn {
				float: right;
				background: #152d53;
				border-radius: 25px;
				font-weight: 400;
				font-size: 13px;
				line-height: 24px;
				color: #fff;
				margin-left: 0 !important;

				@include media($phone_all...) {
					font-size: 12px;
					margin-left: 0;
					padding: 2px 10px;
					min-width: 7rem;
					left: 2.8rem;
					top: 10px;
				}
			}
		}
	}

	.flex_box {
		display: flex;
		flex-direction: column;
	}

	.body {
		@include media($phone_all...) {
			width: 100%;
			max-width: 100%;
			flex-basis: 100%;
		}
	}

	@include media($phone_all...) {
		padding-left: 10px !important;
		padding-right: 10px;
	}
}