.editor {
  @apply rounded-b-lg bg-background text-txt overflow-hidden;

  :global {
    .ql-toolbar {
      @apply border-t border-border-foreground rounded-t-lg;

      .ql-picker-label.ql-active,
      .ql-picker {
        @apply text-white;

        .ql-stroke {
          @apply stroke-white;
        }

        .ql-picker-options {
          @apply bg-neutral-800 border-0 p-0;
        }

        .ql-picker-label:hover {
          @apply text-white;

          .ql-stroke {
            @apply stroke-white;
          }
        }
      }

      .ql-picker-item {
        @apply p-2;

        &:hover {
          @apply bg-black text-white;
        }
      }

      button {
        .ql-picker,
        .ql-stroke {
          @apply stroke-white;
        }

        .ql-fill,
        .ql-stroke.ql-fill {
          @apply fill-white;
        }

        &:hover {
          .ql-stroke {
            @apply stroke-white;
          }

          .ql-fill,
          .ql-stroke.ql-fill {
            @apply fill-white;
          }
        }
      }
    }

    .ql-container {
      @apply border-solid border-border-foreground rounded-b-lg bg-blue-900 overflow-hidden;
    }

    .ql-editor {
      @apply min-h-[10rem] bg-background p-4;

      p {
        @apply text-sm tracking-normal leading-5 font-light my-2;
      }

      // Additional common editor elements
      h1 {
        @apply text-2xl font-bold mb-4;
      }

      h2 {
        @apply text-xl font-semibold mb-3;
      }

      ul, ol {
        @apply ml-4 my-2;
      }

      li {
        @apply my-1;
      }

      blockquote {
        @apply border-l-4 border-gray-300 pl-4 my-2 italic;
      }

      a {
        @apply text-blue-400 hover:text-blue-300 underline;
      }
    }
  }

  &.error {
    h3 {
      @apply text-red-600 font-bold;
    }

    .helper_text {
      @apply text-red-600 font-bold mt-[3px] text-xs italic leading-6 tracking-wider;
    }
  }
}
