@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.works {
	background: transparent url('~assets/images/homepage/grid.webp') no-repeat
		center;
	margin-top: 42px;
	width: 100%;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 3;

	@include media($phone_all...) {
		margin-top: 22px;
	}

	> h2 {
		width: 100%;
		font-style: normal;
		font-weight: 600;
		font-size: 30px;
		line-height: 36px;
		text-align: center;
		letter-spacing: -0.025em;
		background: linear-gradient(180deg, #c362ff 0%, #5d45f6 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;

		@include media($phone_all...) {
			padding-left: 10px;
			width: auto;
		}
	}

	.divider {
		float: left;
		width: 60%;
		height: 2px;
		background: linear-gradient(
			270deg,
			rgba(195, 98, 255, 0.13) 0%,
			#c362ff 63.02%,
			#5d45f7 86.98%,
			rgba(93, 69, 247, 0.09) 100%
		);
	}

	@keyframes wipe-enter {
		0% {
			transform: translateY(-50px);
		}
		100% {
			transform: translateY(-60px);
		}
	}
}

.nws {
	width: 100%;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 3;
	margin-top: 0;

	.news {
		max-width: 1150px;
		width: 100%;
		margin-bottom: 80px;

		> h2 {
			width: 100%;
			font-style: normal;
			font-weight: 600;
			font-size: 30px;
			line-height: 36px;
			text-align: left;
			letter-spacing: -0.025em;
			background: linear-gradient(180deg, #c362ff 0%, #5d45f6 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			text-fill-color: transparent;
			padding-left: 45px;

			@include media($phone_all...) {
				padding-left: 10px;
				width: auto;
			}
		}

		.divider {
			float: left;
			width: 100%;
			height: 2px;
			background: linear-gradient(
				270deg,
				rgba(195, 98, 255, 0.13) 0%,
				#c362ff 63.02%,
				#5d45f7 86.98%,
				rgba(93, 69, 247, 0.09) 100%
			);
		}
	}
}

.tkns {
	width: 100%;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 3;

	.token {
		max-width: 1150px;
		width: 100%;
		margin-bottom: 80px;

		> h2 {
			width: 100%;
			font-style: normal;
			font-weight: 600;
			font-size: 30px;
			line-height: 36px;
			text-align: left;
			letter-spacing: -0.025em;
			background: linear-gradient(180deg, #c362ff 0%, #5d45f6 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			text-fill-color: transparent;
			padding-left: 45px;

			@include media($phone_all...) {
				padding-left: 10px;
				width: auto;
			}
		}

		.divider {
			float: left;
			width: 100%;
			height: 2px;
			background: linear-gradient(
				270deg,
				rgba(195, 98, 255, 0.13) 0%,
				#c362ff 63.02%,
				#5d45f7 86.98%,
				rgba(93, 69, 247, 0.09) 100%
			);
		}
	}
}

.sprfn {
	width: 100%;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 3;

	.superfan {
		max-width: 1047px;
		width: 100%;
		margin-bottom: 80px;

		@include media($phone_all...) {
			margin-bottom: 61px;
		}

		> h2 {
			width: 100%;
			font-style: normal;
			font-weight: 600;
			font-size: 30px;
			line-height: 36px;
			text-align: left;
			letter-spacing: -0.025em;
			background: linear-gradient(180deg, #c362ff 0%, #5d45f6 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			text-fill-color: transparent;
			padding-left: 45px;

			@include media($phone_all...) {
				padding-left: 10px;
				width: auto;
			}
		}

		.divider {
			float: left;
			width: 100%;
			height: 2px;
			background: linear-gradient(
				270deg,
				rgba(195, 98, 255, 0.13) 0%,
				#c362ff 63.02%,
				#5d45f7 86.98%,
				rgba(93, 69, 247, 0.09) 100%
			);
		}
	}
}

.fdao {
	width: 100%;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 3;

	.dao {
		max-width: 1047px;
		width: 100%;
		margin-bottom: 80px;

		> h2 {
			width: 100%;
			font-style: normal;
			font-weight: 600;
			font-size: 30px;
			line-height: 36px;
			text-align: left;
			letter-spacing: -0.025em;
			background: linear-gradient(180deg, #c362ff 0%, #5d45f6 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			text-fill-color: transparent;
			padding-left: 45px;

			@include media($phone_all...) {
				padding-left: 10px;
				width: auto;
			}
		}

		.divider {
			float: left;
			width: 100%;
			height: 2px;
			background: linear-gradient(
				270deg,
				rgba(195, 98, 255, 0.13) 0%,
				#c362ff 63.02%,
				#5d45f7 86.98%,
				rgba(93, 69, 247, 0.09) 100%
			);
		}
	}
}

.gscre {
	width: 100%;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 3;

	.goscore {
		max-width: 1150px;
		width: 100%;

		> h2 {
			width: 100%;
			font-style: normal;
			font-weight: 600;
			font-size: 30px;
			line-height: 36px;
			text-align: left;
			letter-spacing: -0.025em;
			background: linear-gradient(180deg, #c362ff 0%, #5d45f6 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			text-fill-color: transparent;
			padding-left: 45px;

			@include media($phone_all...) {
				padding-left: 10px;
				width: auto;
			}
		}

		.divider {
			float: left;
			width: 100%;
			height: 2px;
			background: linear-gradient(
				270deg,
				rgba(195, 98, 255, 0.13) 0%,
				#c362ff 63.02%,
				#5d45f7 86.98%,
				rgba(93, 69, 247, 0.09) 100%
			);
		}
	}
}

.callout {
	max-width: 1292px;
	width: 100%;
	background: linear-gradient(
		180deg,
		rgba(195, 98, 255, 0.1) 0%,
		rgba(93, 69, 246, 0.1) 100%
	);
	backdrop-filter: blur(12.5px);
	border-radius: 5px;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 3;
	margin: 100px auto 160px auto;

	@include media($phone_all...) {
		margin: 100px auto 100px auto;
	}

	p {
		font-style: normal;
		font-weight: 400;
		font-size: 30px;
		line-height: 42px;
		text-align: center;
		letter-spacing: -0.025em;
		background: linear-gradient(180deg, #c362ff 0%, #5d45f6 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;

		@include media($phone_all...) {
			font-size: 25px;
			line-height: 32px;
		}
	}
}

.footer {
	background: rgba(255, 255, 255, 0.05);
	width: 100%;
	height: auto;
	display: flex;
	align-items: flex-start;
	justify-content: space-evenly;
	position: relative;
	z-index: 4;
	padding: 98px 0 88px 0;

	@include media($phone_all...) {
		display: inline-block;
		padding: 40px 30px 20px;
		margin-left: 0;

		div {
			float: left;
			width: 49%;
			margin-bottom: 20px;
		}
	}

	ul {
		li {
			font-size: 16px;
			font-weight: 600;
			margin-bottom: 16px;

			a {
				color: #fff;

				&:hover {
					color: #5eccf2;
				}
			}

			svg {
				margin-right: 16px;
			}
		}

		&.plain {
			li {
				font-weight: normal;
			}
		}
	}
}

.curly {
	z-index: 2;
	position: absolute;
	height: 350px;
	width: 350px;
	left: -10px;
	top: 2370px;

	img {
		width: 100%;
	}
}

.end_box {
	z-index: 2;
	position: absolute;
	height: 450px;
	width: 450px;
	left: -10px;
	bottom: 470px;
	@include media($phone_all...) {
		width: 100%;
	}
	img {
		width: 100%;
	}
}

.dots1 {
	z-index: 2;
	position: absolute;
	height: 250px;
	width: 250px;
	right: 0px;
	top: 3300px;

	img {
		width: 100%;
	}
}

.dots2 {
	z-index: 2;
	position: absolute;
	height: 210px;
	width: 210px;
	left: -30px;
	top: 1850px;

	img {
		width: 100%;
	}
}

.triangle {
	z-index: 2;
	position: absolute;
	height: 410px;
	width: 410px;
	right: 0px;
	top: 1150px;

	img {
		width: 100%;
	}
}

.rectangle {
	z-index: 2;
	position: absolute;
	height: 210px;
	width: 210px;
	right: 0px;
	bottom: 1250px;

	img {
		width: 100%;
	}
}
